export default function IconGoogle() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 96 96" id="Google-Icon--Streamline-Svg-Logos">
      <desc>Google Icon Streamline Icon: https://streamlinehq.com</desc>
      <path
        fill="#4285F4"
        d="M94.0604 49.0444c0 -3.8644 -0.3136 -6.6844 -0.9922 -9.6088H48.9404v17.442h25.902c-0.522 4.3346 -3.342 10.8624 -9.6088 15.2488l-0.0878 0.584 13.9524 10.8087 0.9666 0.0965c8.8776 -8.199 13.9956 -20.2624 13.9956 -34.5712Z"
        stroke-width="1"
      ></path>
      <path
        fill="#34A853"
        d="M48.9399 95c12.6898 0 23.343 -4.178 31.1244 -11.3844L65.2331 72.1265c-3.9688 2.7678 -9.2956 4.7 -16.2932 4.7 -12.4288 0 -22.9776 -8.1987 -26.738 -19.5309l-0.5511 0.0468L7.14285 68.5702l-0.18973 0.5274C14.6819 84.4509 30.5575 95 48.9399 95Z"
        stroke-width="1"
      ></path>
      <path
        fill="#FBBC05"
        d="M22.2025 57.2956c-0.9923 -2.9244 -1.5665 -6.058 -1.5665 -9.2956 0 -3.238 0.5743 -6.3712 1.5143 -9.2956l-0.0263 -0.6228L7.43426 26.6734l-0.48062 0.2286C3.76823 33.2732 1.94043 40.4278 1.94043 48c0 7.5722 1.8278 14.7264 5.01321 21.0976l15.24886 -11.802Z"
        stroke-width="1"
      ></path>
      <path
        fill="#EB4335"
        d="M48.9399 19.1732c8.8254 0 14.7786 3.8122 18.1732 6.998L80.3775 13.22C72.2311 5.6478 61.6297 1 48.9399 1 30.5575 1 14.6819 11.5488 6.95312 26.902L22.1497 38.7044c3.8126 -11.3322 14.3614 -19.5312 26.7902 -19.5312Z"
        stroke-width="1"
      ></path>
    </svg>
  );
}
